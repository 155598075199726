import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Sauna from "../containers/sauna";

const SaunalPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Sauna />
  </Layout>
);

export default SaunalPage;
