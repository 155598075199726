import * as React from "react";
const Sauna = () => (
  <div className="flex flex-wrap overflow-hidden">
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-1">
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654842428/training%20center/sauna/sauna-elite-center_orvwxw.webp"
        alt="hua hin muay thai team"
      />
    </div>

    <div className="bg-geraniumlake w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 p-8 lg:p-16 order-2 ">
      <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold py-12 text-white">
        Separate Male and Female Saunas
      </h2>
      <h3 className="text-xl md:text-2xl lg:text-3xl font-light py-4 text-white">
        Rest and Recover
      </h3>
      <p className="text-xl md:text-3xl lg:text-xl py-6 text-white leading-loose md:leading-loose lg:leading-loose uppercase lg:pr-20">
        At Elite Training Center provides separated male and female saunas for
        rest and recovery after an intense training session and at no additional
        cost.
      </p>
    </div>
    <div className="bg-white pt-16 md:pt-20 md:pb-16 p-8 lg:p-20 w-full overflow-hidden  lg:w-1/2 xl:w-1/2 order-4 lg:order-3">
      <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold pb-12  text-geraniumlake">
        Heat for Health
      </h2>
      <h3 className="text-xl md:text-2xl lg:text-3xl font-light py-4 text-white">
        Crank up the heat
      </h3>
      <p className="text-xl md:text-3xl lg:text-xl py-2 text-black leading-loose md:leading-loose lg:leading-loose uppercase">
        Everyone who have been in a sauna will experience an increase in their
        blood circulation. Saunas promote bllood circulation because the heat
        causes your heart to beat faster, and your blood vessels to widen. This
        helps the flow of blood to make its way around your body much more
        freely. ​<br />
        Saunas help to improve:
      </p>
      <ul className="list-disc text-xl md:text-3xl lg:text-xl py-2 text-black leading-loose px-12">
        <li className="py-2">Cardiovascular performance ​</li>
        <li className="py-2">Relaxing muscles</li>
        <li className="py-2">Soothe aches &amp; pains</li>
        <li className="py-2">Flush toxins</li>
        <li className="py-2">Cleanses your skin</li>
        <li className="py-2">Relieves stress</li>
      </ul>
    </div>
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-3 lg:order-4">
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654842428/training%20center/sauna/clean-sauna_kdobpi.webp"
        alt="exercises gym training bangkok muay thai team"
      />
    </div>
  </div>
);

export default Sauna;
